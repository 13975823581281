import * as React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import moment from 'moment'
import Layout from '../components/layout'
import Header from '../components/header'
import Container from '../components/container'
import Row from '../components/row'
import Column from '../components/column'
import StoryTile from '../components/story-tile'
import PortfolioTile from '../components/portfolio-tile'
import Map from '../components/map'

import {
    EnergyIcon,
    PlayIcon,
    OnSwitchIcon,
    CheckIcon,
    MonitorIcon,
    HandshakeIcon
}from '../components/icons'

import headerVideoWebM from '../videos/Header_Video.webm'
import headerVideoMp4 from '../videos/Header_Video.mp4'

const IndexPage = ({ location }) => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "stories"}}, sort: {fields: name}) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              width: 480
              height: 480
              transformOptions: {
                cropFocus: ATTENTION
              }
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      allContentfulAktuelles(
        filter: {
          company: { elemMatch: { name: { eq: "GE-T" } } }
          node_locale: { eq: "en" }
        }
        sort: {fields: publishedAt, order: DESC}
        limit: 2
      ) {
        nodes {
          title
          slug
          publishedAt
          excerpt {
            excerpt
          }
        }
      }
    }
    `)

    const PageHeader = <Header className="home-header" title="GE­-T fascinated." backgroundVideoWebM={headerVideoWebM} backgroundVideoMp4={headerVideoMp4} />


    return (
        <Layout
            translatedPageLinks={[{ code: 'de', link: '/de/' }, { code: 'en', link: '/en/' }]}
            pageLang="en"
            pageTitle="Home"
            pageHeader={PageHeader}
            lightNavbar
            location={location}
        >
            <section id="about">
                <Container>
                    <p style={{ fontSize: '25px', fontWeight: 600 }}>People. Mobility. Living. In the future, these three components can no longer be thought of separately. They interact with each other as an ecosystem.</p>
                    <p>
                        As a forge for innovative technology, GE-T develops tangible intelligent control elements.
                        We are pursuing the vision of teaching the car and the home of the future to feel, hear and see.
                        Our touch technology is already currently being used both in vehicle interiors and in the smarthome.
                    </p>
                    <p>
                        With bi-directional charging, car and home will enter into a symbiosis.
                        We think ahead, because we know that our world is on an acceleration course.
                        Vehicle development is getting faster, and market launch cycles shorter.
                        That's why we have buckled down and are focusing on fast, customised and intelligent solutions.
                    </p>
                </Container>
            </section>
            <section id="stories" className="blue-section">
                <Container>
                    <h2>Stories</h2>
                    <div className="stories">
                        <Row>
                            <Column columnWidth={4}>
                                <StoryTile to="/en/story/touch-steering-wheel/" image={data.allFile.nodes[0]} title="Touch Steering Wheel" animationDuration="200" />
                            </Column>
                            <Column columnWidth={4}>
                                <StoryTile to="/en/story/door-control-module/" image={data.allFile.nodes[2]} title="Door control module" animationDuration="400" />
                            </Column>
                            <Column columnWidth={4}>
                                <StoryTile to="/en/story/ev-charger/" image={data.allFile.nodes[3]} title="EV charger" animationDuration="600" />
                            </Column>
                            <Column columnWidth={4}>
                                <StoryTile to="/en/story/test-finger/" image={data.allFile.nodes[1]} title="Test finger" animationDuration="800" />
                            </Column>
                        </Row>
                    </div>
                </Container>
            </section>
            <section id="portfolio">
                <Container>
                    <h2>Portfolio</h2>
                    <Row>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Innovation Hub" icon={<EnergyIcon />}>
                                Innovative ideas inspire us.
                                We support you in the development and implementation process, and offer complete supervision of the entire process – from the first stroke to the finished product.
                                The Innovation Hub forms the common platform for this.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Interior Design " icon={<PlayIcon />} animationOffset="250">
                                We are passionately dedicated to designing a comfortable, aesthetically pleasing as well as functional vehicle interior.
                                From appropriate hardware and software to lighting and acoustic concepts, haptic feedback and flexible displays - we develop the relevant components.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Touch Competence Center" icon={<OnSwitchIcon />}>
                                Our Touch Competence Center is fully dedicated to the field of touch technology.
                                Capacitive operating elements as well as displays are subjected to detailed, component-specific testing using a variety of individual test procedures.
                                In addition, we set up test rigs, define requirements for components, and evaluate and select test methods.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Test House" icon={<CheckIcon />} animationOffset="250">
                                Can a wallbox withstand extreme sub-zero temperatures? What influence do dust particles have on the functionality of a steering wheel?
                                In our test house, we have extensive laboratory capacity to test the durability of various components:
                                a battery laboratory and an acoustic laboratory, as well as test boxes that simulate various environmental influences.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Software and App Development" icon={<MonitorIcon />}>
                                We develop hardware and software for embedded systems, as well as apps for controlling and evaluating a wide variety of systems.
                                Furthermore, artificial intelligence (AI) development is also part of our portfolio.
                            </PortfolioTile>
                        </Column>
                        <Column columnWidth={2}>
                            <PortfolioTile title="Resident Engineering" icon={<HandshakeIcon />} animationOffset="250">
                                Our resident engineers support globally positioned suppliers in finding an entry point into the Volkswagen group’s complex structures, optimising their own process flows and simplifying communication channels.
                                They are excellently networked with both the Volkswagen Group and ES-Tec GmbH.
                            </PortfolioTile>
                        </Column>
                    </Row>
                </Container>
            </section>
            <section id="news" className="blue-section">
                <Container>
                    <h2>News</h2>
                    <Row>
                        {data.allContentfulAktuelles.nodes.map((article) => {
                            const publishedAt = moment(article.publishedAt).format(
                                'DD.MM.YYYY'
                            )

                            return (
                                <Column columnWidth={2} key={article.slug}>
                                    <Link
                                        className="article"
                                        to={'/en/news/' + article.slug + '/'}
                                    >
                                        <div className="date">
                                            <h5>{publishedAt}</h5>
                                        </div>

                                        <div className="title">
                                            <h3>{article.title}</h3>
                                        </div>

                                        <div className="excerpt">
                                            <p>{article.excerpt.excerpt}&hellip;</p>
                                        </div>

                                        <div className="read-more">
                                            <span className="button button-white">Read more</span>
                                        </div>
                                    </Link>
                                </Column>
                            )
                        })}
                    </Row>
                </Container>
            </section>
            <section id="contact">
                <Container>
                    <h2>Contact</h2>
                    <Row>
                        <Column columnWidth={3}>
                            <h3>GE-T GmbH</h3>
                            <p>
                                Marie-Curie-Str. 6<br />
                                85055 Ingolstadt
                            </p>
                            <p>
                                Email: info<span style={{ display: 'none' }}>sp0m</span>@ge-t.de<br />
                                Phone: +49 (0) 841 14 90 76 99
                            </p>
                        </Column>
                        <Column columnWidth={3}>
                            <h3>Business premises in Wolfsburg</h3>
                            <p>
                                Test laboratory/workshop<br />
                                Brandgehaege 16<br />
                                38444 Wolfsburg<br />
                            </p>
                            <p>Phone: +49 (0) 53 08 52 11 22 00</p>
                        </Column>
                        <Column columnWidth={3}>
                            <h3>Business premises in Berlin</h3>
                            <p>
                                Albert-Einstein-Straße 14<br />
                                (Technologiepark Adlershof)<br />
                                12489 Berlin
                            </p>
                            <p>Phone: +49 (0) 53 08 52 11 22 00</p>
                        </Column>
                    </Row>
                </Container>
            </section>
            <Map lat="52.37063840305022" lng="10.727009378606782" zoom="16" addPinAtCenter />
        </Layout>
    )
}

export default IndexPage